import React from "react";
import { graphql } from "gatsby";
import _ from "lodash";
import Truncate from "react-truncate";

import {
  AppImage,
  AppSlider,
  Layout,
  // LogoIcon,
  PublicationsSection,
} from "../components";
import processDate from "../utils/processDate";

const NewsMedia = ({ data }) => {
  const mediaArticlesArray = _.orderBy(
    _.get(data, "allContentfulMediaArticle.nodes"),
    "date",
    "desc"
  );

  const mediaSlide = (mediaArticle, index, aspectRatio, featured) => {
    return (
      <div className={`media-slide${featured ? "" : " no-description"}`}>
        <a
          className="link-container"
          href={_.get(mediaArticle, "url")}
          target="_blank"
          rel="noopener noreferrer"
        >
          <AppImage
            className="article-image"
            image={_.get(mediaArticle, "image") || _.get(mediaArticle, "logo")}
            aspectRatio={aspectRatio}
            imgStyle={{
              objectFit:
                !_.get(mediaArticle, "image") && _.get(mediaArticle, "logo")
                  ? "contain"
                  : "cover",
            }}
          />
          {/*!mediaArticle.image && <LogoIcon />*/}
          {_.get(mediaArticle, "image") ? (
            <div className="source-image-container">
              <AppImage
                className={`source-image`}
                image={_.get(mediaArticle, "logo")}
              />
            </div>
          ) : null}
          <div className={`data-container`} role="none">
            <div className="title-row">
              {featured ? (
                <h2 className="h4 title">
                  <Truncate lines={2} trimWhitespace>
                    {_.get(mediaArticle, "title")}
                  </Truncate>
                </h2>
              ) : (
                <h3 className="h4 title">
                  <Truncate lines={2} trimWhitespace>
                    {_.get(mediaArticle, "title")}
                  </Truncate>
                </h3>
              )}
              <p className="date">{processDate(_.get(mediaArticle, "date"))}</p>
            </div>
            {featured && (
              <p className="description">
                <Truncate lines={2} trimWhitespace>
                  {_.get(mediaArticle, "description.internal.content")}
                </Truncate>
              </p>
            )}
          </div>
        </a>
      </div>
    );
  };

  return (
    <Layout className="news-views" pageData={_.get(data, "contentfulPageData")}>
      <section>
        <AppSlider className="featured-slider" adaptiveHeight>
          {mediaArticlesArray.slice(0, 5).map((mediaArticle, index) => {
            return mediaSlide(mediaArticle, index, 2 / 1, true);
          })}
        </AppSlider>
      </section>
      <section>
        <h2 className="h3">Media</h2>
        <hr />
        <AppSlider
          slidesToShow={5}
          slidesToScroll={5}
          initialSlide={5}
          adaptiveHeight
        >
          {mediaArticlesArray.map((mediaArticle, index) => {
            return mediaSlide(mediaArticle, index, 1, false);
          })}
        </AppSlider>
      </section>
      <PublicationsSection
        data={_.get(data, "allContentfulPressRelease")}
        title="Press Releases"
      />
    </Layout>
  );
};

export default NewsMedia;

export const query = graphql`
  query newsMediaQuery {
    contentfulPageData(title: { eq: "News & Views" }) {
      title
      description
      image {
        file {
          url
        }
      }
      header
    }
    allContentfulMediaArticle {
      nodes {
        title
        image {
          title
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        logo {
          title
          fluid(maxWidth: 500, quality: 90, resizingBehavior: PAD) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        description {
          internal {
            content
          }
        }
        date
        url
      }
    }
    allContentfulPressRelease {
      nodes {
        title {
          childMarkdownRemark {
            html
          }
          internal {
            content
          }
        }
        titleWithSubscripts {
          childMarkdownRemark {
            html
          }
        }
        date
      }
    }
  }
`;
